<template>
    <span
        :class="`inline-flex justify-center items-center px-2.5 py-0.5 rounded-${roundSize} font-medium whitespace-nowrap 
        ${biggerText ? 'text-sm' : 'text-xs'} ${bold ? 'font-bold' : 'font-semibold'}`"
        :style="style"
    >
        <slot name="icon"> </slot>

        {{ text }}
    </span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    text: { type: [String, Number], default: '' },
    color: { type: String, default: 'green' },
    roundSize: { type: String, default: 'full' },
    textColor: { type: String, default: 'white' },
    biggerText: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
})

const style = computed(() => {
    let properties = {}
    if (props.light)
        properties = {
            border: 'solid 1px ' + props.color,
            color: props.color,
        }
    else
        properties = {
            'background-color': props.color,
            'color': props.textColor,
        }

    return properties
})
</script>
