/** @type {import('tailwindcss').Config} */
export default {
    darkMode: 'class', // Activer le mode sombre
    content: ['./public/**/*.html', './src/**/*.{vue,js,ts,jsx,tsx,woff,woff2}'],
    theme: {
        extend: {
            backgroundSize: {
                'size-200': '200% 200%',
            },
            backgroundPosition: {
                'pos-0': '0% 0%',
                'pos-100': '100% 100%',
            },
            padding: {
                header: '52px',
                title: '36px',
                banner: '44px',
            },
            height: {
                104: '26rem',
                112: '28rem',
                128: '32rem',
            },
            fontSize: {
                'xxs': '10px',
                'sm': ['14px', { letterSpacing: '0.6px', lineHeight: '19px' }],
                'base': ['15px', { letterSpacing: '-0.23px', lineHeight: '20px' }],
                'lg': ['17px', { letterSpacing: '-0.43px', lineHeight: '22px' }],
                'xl': ['22px', { letterSpacing: '-0.22px', lineHeight: '26px' }],
                '2xl': ['26px', { letterSpacing: '0.22px', lineHeight: '32px' }],
            },
            colors: {
                white: 'rgb(var(--white))',
                dark: {
                    base: 'rgb(var(--dark-base))',
                    light: 'rgb(var(--dark-light))',
                    lighter: 'rgb(var(--dark-lighter))',
                    lightest: 'rgb(var(--dark-lightest))',
                },
                text: {
                    base: 'rgb(var(--text-base))',
                    darker: 'rgba(var(--text-darker))',
                    gray: 'rgb(var(--text-gray))',
                    black: 'rgb(var(--text-black))',
                },
                border: 'rgba(var(--border))',
                brand: {
                    base: 'rgb(var(--brand-base))',
                    yellow: 'rgb(var(--brand-yellow))',
                    gold: 'rgb(var(--brand-gold))',
                    teal: 'rgb(var(--brand-teal))',
                    red: 'rgb(var(--brand-red))',
                },
                state: {
                    red: 'rgb(var(--state-red))',
                    green: 'rgb(var(--state-green))',
                    orange: 'rgb(var(--state-orange))',
                    yellow: 'rgb(var(--state-yellow))',
                },
                stoodOut: 'rgb(var(--stoodOut))',
                avgJumps: 'rgb(var(--avgJumps))',
                strikePower: 'rgb(var(--strikePower))',
                palette: [
                    'rgb(var(--palette-1))',
                    'rgb(var(--palette-2))',
                    'rgb(var(--palette-3))',
                    'rgb(var(--palette-4))',
                    'rgb(var(--palette-5))',
                    'rgb(var(--palette-6))',
                    'rgb(var(--palette-7))',
                    'rgb(var(--palette-8))',
                    'rgb(var(--palette-9))',
                    'rgb(var(--palette-10))',
                    'rgb(var(--palette-11))',
                    'rgb(var(--palette-12))',
                    'rgb(var(--palette-13))',
                    'rgb(var(--palette-14))',
                    'rgb(var(--palette-15))',
                    'rgb(var(--palette-16))',
                    'rgb(var(--palette-17))',
                    'rgb(var(--palette-18))',
                    'rgb(var(--palette-19))',
                    'rgb(var(--palette-20))',
                    'rgb(var(--palette-21))',
                    'rgb(var(--palette-22))',
                ],
                direction: {
                    left: 'rgb(var(--direction-left))',
                    right: 'rgb(var(--direction-right))',
                },
                gaits: {
                    canter: 'rgb(var(--gaits-canter))',
                    trot: 'rgb(var(--gaits-trot))',
                    walk: 'rgb(var(--gaits-walk))',
                    standing: 'rgb(var(--gaits-standing))',
                    all: 'rgb(var(--gaits-all))',
                },
                banner: {
                    info: {
                        text: 'rgb(var(--white))',
                        background: 'rgb(var(--banner-info-bg))',
                    },
                    warning: {
                        text: 'rgb(var(--white))',
                        background: 'rgb(var(--banner-warning-bg))',
                    },
                    danger: {
                        text: 'rgb(var(--white))',
                        background: 'rgb(var(--banner-danger-bg))',
                    },
                },
                button: {
                    base: 'rgb(var(--button-base))',
                    warning: 'rgb(var(--button-warning))',
                    danger: 'rgb(var(--button-danger))',
                    dark: {
                        lighter: 'rgb(var(--button-dark-lighter))',
                        lightest: 'rgba(var(--button-dark-lightest))',
                    },
                },
                polar: {
                    zone1: 'rgb(var(--polar-zone1))',
                    zone2: 'rgb(var(--polar-zone2))',
                    zone3: 'rgb(var(--polar-zone3))',
                    zone4: 'rgb(var(--polar-zone4))',
                    zone5: 'rgb(var(--polar-zone5))',
                },
                analysis: [
                    'rgb(var(--analysis1))',
                    'rgb(var(--analysis2))',
                    'rgb(var(--analysis3))',
                    'rgb(var(--analysis4))',
                ],
                gradient: [
                    'rgb(var(--gradient-1))',
                    'rgb(var(--gradient-2))',
                    'rgb(var(--gradient-3))',
                    'rgb(var(--gradient-4))',
                    'rgb(var(--gradient-5))',
                    'rgb(var(--gradient-6))',
                    'rgb(var(--gradient-7))',
                    'rgb(var(--gradient-8))',
                    'rgb(var(--gradient-9))',
                    'rgb(var(--gradient-10))',
                ],
                discipline: {
                    'jumping': ['rgb(var(--discipline-jumping1))', 'rgb(var(--discipline-jumping2))'],
                    'dressage': ['rgb(var(--discipline-dressage1))', 'rgb(var(--discipline-dressage2))'],
                    'cross': ['rgb(var(--discipline-cross1))', 'rgb(var(--discipline-cross2))'],
                    'endurance': ['rgb(var(--discipline-endurance1))', 'rgb(var(--discipline-endurance2))'],
                    'racing': ['rgb(var(--discipline-racing1))', 'rgb(var(--discipline-racing2))'],
                    'reining': ['rgb(var(--discipline-reining1))', 'rgb(var(--discipline-reining2))'],
                    'barrel-racing': ['rgb(var(--discipline-barrel-racing1))', 'rgb(var(--discipline-barrel-racing2))'],
                    'gymnastics': ['rgb(var(--discipline-gymnastics1))', 'rgb(var(--discipline-gymnastics2))'],
                    'flat-work': ['rgb(var(--discipline-flat-work1))', 'rgb(var(--discipline-flat-work2))'],
                    'outriding': ['rgb(var(--discipline-outriding1))', 'rgb(var(--discipline-outriding2))'],
                    'default': ['rgb(var(--discipline-default1))', 'rgba(var(--discipline-default2))'],
                },
            },
        },
    },
    plugins: [import('tailwindcss-primeui'), import('@tailwindcss/forms'), import('@tailwindcss/aspect-ratio')],
}
