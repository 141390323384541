import { createApp } from 'vue'
import AppComponent from './app.vue'
// import { App } from '@capacitor/app'
import router from '@/router'
import store from '@/store'
import sensor from '@/sensor'
import { initSentry } from '@/sentry'
import { i18n } from '@/i18n'
import mapboxgl from 'mapbox-gl'
import { IonicVue } from '@ionic/vue'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import BannerPlugin from '@/plugins/banner'
import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import directives from '@/directives' // Import the directives module

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)
import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'

import './assets/css/app.css'
import './assets/css/theme.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import './assets/fonts/inter.css'
import './assets/css/ion/base.css'
import './assets/css/ion/toast.css'
import './assets/css/ion/modal.css'
import './assets/css/animations.css'

if (Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.hide()
}
if (Capacitor.isPluginAvailable('SplashScreen')) {
    SplashScreen.show()
}

const getConfig = () => {
    let config = {
        mode: 'ios',
        swipeBackEnabled: true,
    }

    // if (isPlatform('iphone')) {
    //     config = {
    //         ...config,
    //         backButtonText: 'Previous',
    //     }
    // }

    return config
}

// Bootstrap application services.
mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_TOKEN
// StatusBar.setOverlaysWebView({ overlay: true }) //Android only

// Store the cordova info when the device is ready.
document.addEventListener('deviceready', () => store.commit('device/cordova_info', window.device), false)

// Deep link to open mobile app if installed
// See https://capacitorjs.com/docs/guides/deep-links
CapacitorApp.addListener('appUrlOpen', (event) => {
    console.log('### DEEPLINK ###', event)
    const slug = event.url.split('app.alogo.io').pop()
    // We only push to the route if there is a slug present
    if (slug) {
        const paramArr = slug.slice(slug.indexOf('?') + 1).split('&')
        const query = {}
        paramArr.map((param) => {
            const [key, val] = param.split('=')
            query[key] = decodeURIComponent(val)
        })
        router.push({
            path: slug,
            query: query,
        })
    } else {
        router.push({ name: 'index' })
    }
})

const CustomAuraTheme = definePreset(Aura, {
    semantic: {
        primary: {
            50: 'rgb(var(--primary-50))', // Utilise les différentes nuances de primary
            100: 'rgb(var(--primary-100))',
            200: 'rgb(var(--primary-200))',
            300: 'rgb(var(--primary-300))',
            400: 'rgb(var(--primary-400))',
            500: 'rgb(var(--primary-500))', // Couleur principale
            600: 'rgb(var(--primary-600))',
            700: 'rgb(var(--primary-700))',
            800: 'rgb(var(--primary-800))',
            900: 'rgb(var(--primary-900))',
            950: 'rgb(var(--primary-950))', // Couleur pour les éléments très foncés
        },
        colorScheme: {
            light: {
                primary: {
                    color: 'rgb(var(--primary))', // Couleur principale
                    inverseColor: 'rgb(var(--primary-inverse))', // Couleur inversée (texte)
                    hoverColor: 'rgb(var(--primary-hover))', // Couleur au survol
                    activeColor: 'rgb(var(--primary-active-color))', // Couleur au clic
                },
                highlight: {
                    background: 'rgb(var(--primary-hover))', // Surbrillance
                    focusBackground: 'rgb(var(--primary-active-color))', // Focus fond
                    color: 'rgb(var(--primary-inverse))',
                    focusColor: 'rgb(var(--primary-inverse))',
                },
                surface: {
                    0: 'rgb(var(--surface-0))', // Fond global
                    50: 'rgb(var(--surface-50))',
                    100: 'rgb(var(--surface-100))',
                    200: 'rgb(var(--surface-200))',
                    300: 'rgb(var(--surface-300))',
                    400: 'rgb(var(--surface-400))',
                    500: 'rgb(var(--surface-500))',
                    600: 'rgb(var(--surface-600))',
                    700: 'rgb(var(--surface-700))',
                    800: 'rgb(var(--surface-800))',
                    900: 'rgb(var(--surface-900))',
                    950: 'rgb(var(--surface-950))',
                },
            },
            dark: {
                primary: {
                    color: 'rgb(var(--primary))', // Couleur principale en sombre
                    inverseColor: 'rgb(var(--primary-inverse))', // Couleur inversée
                    hoverColor: 'rgb(var(--primary-hover))', // Survol en sombre
                    activeColor: 'rgb(var(--primary-active-color))', // Clic en sombre
                },
                highlight: {
                    background: 'rgb(var(--primary-hover))', // Surbrillance en sombre
                    focusBackground: 'rgb(var(--primary-active-color))', // Focus en sombre
                    color: 'rgba(var(--primary-inverse), var(--primary-highlight-opacity))', // Texte en surbrillance
                    focusColor: 'rgba(var(--primary-inverse), var(--primary-highlight-hover-opacity))',
                },
                surface: {
                    0: 'rgb(var(--surface-0))', // Fond sombre
                    50: 'rgb(var(--surface-50))',
                    100: 'rgb(var(--surface-100))',
                    200: 'rgb(var(--surface-200))',
                    300: 'rgb(var(--surface-300))',
                    400: 'rgb(var(--surface-400))',
                    500: 'rgb(var(--surface-500))',
                    600: 'rgb(var(--surface-600))',
                    700: 'rgb(var(--surface-700))',
                    800: 'rgb(var(--surface-800))',
                    900: 'rgb(var(--surface-900))',
                    950: 'rgb(var(--surface-950))',
                },
            },
        },
    },
})

// Create the vue 3 application.
const app = createApp(AppComponent)
    .use(router)
    .use(store)
    .use(i18n)
    .use(IonicVue, getConfig())
    .use(BannerPlugin)
    .use(sensor)
    .use(directives)
    .use(PrimeVue, {
        theme: {
            preset: CustomAuraTheme,
            options: {
                darkModeSelector: '.dark',
                cssLayer: {
                    name: 'primevue',
                    order: 'tailwind-base, primevue, tailwind-utilities',
                },
            },
        },
    })

app.config.performance = !import.meta.env.PROD

// finally on tablet we want to show the web version of the app
// const isBuildForMobile = false
const isBuildForMobile = import.meta.env.VITE_APP_MOBILE === 'true' || import.meta.env.VITE_APP_MOBILE === true

const isMobile = () => {
    if (isBuildForMobile) {
        return true
    } else if (window.innerWidth >= 1024) {
        return false
        // media queries => md
    } else {
        return true
    }
}
app.config.globalProperties.$isMobile = isMobile()
app.provide('isMobile', app.config.globalProperties.$isMobile)
app.config.globalProperties.$isBuildForMobile = isBuildForMobile
app.provide('isBuildForMobile', isBuildForMobile)

let timer = undefined
const onResize = (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
        let vh = window.innerHeight * 0.01
        // console.log('### vh', vh, document.documentElement.clientHeight, window.innerHeight)
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        // console.log('###### onResize ###### ', window.innerWidth, window.innerHeight)
        if (app.config.globalProperties.$isMobile !== isMobile()) {
            return location.reload()
        }
    }, 100)
}

// let routerIsReady = false
initSentry(app, router, isBuildForMobile)

// Initiate the application when the router is ready.
router.isReady().then(() => {
    // routerIsReady = true
    app.mount('#app')
    onResize()
    window.addEventListener('resize', onResize)
})
